<template>
  <div>
    <v-row>
      <!-- Influencing The Influencer -->
      <v-col cols="12" class="align-center">
        <v-img width="150" :src="store.state.user.empresaActual.imagen2"></v-img>
        <v-card-title>{{ store.state.user.empresaActual.razonSocial }}</v-card-title>
        <v-card-subtitle>{{ store.state.sucursalSelect.nombre }}</v-card-subtitle>
        <v-card-text>
          <p>
            Bienvenido a ArtySystem. Este sistema le permite gestionar de forma eficiente y segura todos los procesos
            relacionados con su negocio. Con este sistema podrá emitir facturas electrónicas, controlar el stock de sus
            productos, registrar sus compras y ventas, y hacer seguimiento a sus pedidos. Además, podrá generar reportes
            e informes personalizados para analizar el rendimiento de su empresa. El sistema es fácil de usar y se
            adapta a sus necesidades específicas.
          </p>
        </v-card-text>

        <v-card-text>
          <v-row justify="center">
            <v-col cols="12" md="6" lg="3">
              <v-card   :color="'primary'" dark height="200">
                <v-img
                  src="https://www.nueva-iso-9001-2015.com/wp-content/uploads/2022/03/Entregar-lo-pedido.jpg"
                  height="200"
                />
                <v-card-text>
                  <div class="text-h6 flex-grow-1 text-center black--text">Pedido</div>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" md="6" lg="3">
              <v-card   :color="'primary'" dark height="200">
                <v-img src="https://blog.bextok.com/wp-content/uploads/2017/06/jefe-de-compras.jpg" height="200" />
                <v-card-text>
                  <div class="text-h6 flex-grow-1 text-center black--text">Compras</div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="6" lg="3">
              <v-card :color="'primary'" dark height="200">
                <v-img src="https://concep.es/wp-content/uploads/2022/10/shutterstock_2081409133.jpg" height="200" />
                <v-card-text>
                  <div class="text-h6 flex-grow-1 text-center black--text">Ventas</div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row justify="center" class="mt-12">
            <v-col cols="12" md="6" lg="3">
              <v-card  :color="'primary'" dark height="200">
                <v-img
                  src="https://www.stelorder.com/wp-content/uploads/2022/08/portada-control-inventario-opt.jpg"
                  height="200"
                />
                <v-card-text>
                  <div class="text-h6 flex-grow-1 text-center black--text">Ajustes</div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="6" lg="3">
              <v-card   :color="'primary'" dark height="200">
                <v-img
                  src="https://thelogisticsworld.com/wp-content/uploads/2021/12/transportistas-anotando-datos-frente-a-un-camion.jpg"
                  height="200"
                />
                <v-card-text>
                  <div class="text-h6 flex-grow-1 text-center black--text">Translados</div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-col>
    </v-row>
   
  </div>
</template>

<script>
import CardBasic from '@/views/cards/CardBasic.vue'
import CardNavigation from '@/views/cards/CardNavigation.vue'
import CardSolid from '@/views/cards/CardSolid.vue'
import router from '@/router'
import store from '@/store'

export default {
  components: {
    CardBasic,
    CardNavigation,
    CardSolid,
  },

  setup() {
    const click = ruta => {
      router.push(ruta)
    }
    return {
      click,
      store,
    }
  },
}
</script>
